import {openPanelInModal} from "@Components/modal-container/modal-container-reducer";
import {MODALS_IDS} from "@Components/modal-container";
import type {EventsIframeModalProps} from "@Modals/event-preview-modal/event-preview-modal.types";

export const openEventPreviewModal = (props: EventsIframeModalProps): void => {
    window.PMW.redux.store.dispatch(
        openPanelInModal({
            modalId: MODALS_IDS.EVENT_PREVIEW_MODAL,
            panelProps: props,
        })
    );
}